/* === FONTS *font-xxx* === */

$font-header:           'century-gothic', Helvetica Neue, Helvetica, Arial, sans-serif;
$font-body:             'azo-sans-web', Helvetica Neue, Helvetica, Arial, sans-serif;

$font-xxl:              3.2em;
$font-xxl:              clamp(1.8em, -0.875em + 4.5vw, 3.2em);

$font-xl:               2.8em;
$font-xl:               clamp(1.7em, -0.875em + 4.5vw, 2.8em);

$font-large:            1.6em;
$font-large:            clamp(1.2em, -0.875em + 4.5vw, 1.6em);

$font-medium:           1.15em;
$font-medium:           clamp(1em, -0.875em + 4.5vw, 1.15em);

$font-regular:          1em;
$font-small:            0.85em;
$font-xs:               0.7em;

$clr-accessible:        #ffdd00;
$clr-primary:           #9d302a;
$clr-secondary:         #344657;
$clr-success:           #0da50d;
$clr-error:             #dc143c;

$clr-black:             #000;
$clr-text:              #444;
$clr-dark:              #1e1d1d;
$clr-white:             #fff;
$clr-shade:             darken($clr-white, 5%);
$clr-light-shade:       darken($clr-white, 2%);
$clr-background:        #fffffb;

/* === MISC. === */
$border-radius:         4px;
$bold-weight:           700;
$padding:               3rem 0;