@import '_partials/variables.scss';
@import '_partials/mixins.scss';

/*------------------------------------*\
    #TEMPLATE
\*------------------------------------*/

.page-content {
    h2 {
        font-size: 1.6em;
        font-size: $font-large;
    }
    h3 {
        font-size: 1.15em;
        font-size: $font-medium;
    }
    h4, h5, h6 {
        $font-size: $font-regular;
    }
    h1,h2,h3,h4,h5,h6 {
        color: $clr-secondary;
        display: inline-block;
        margin-bottom: 0;
    
        @supports (background-clip: text) or (-webkit-background-clip: text) {
            background: $clr-secondary linear-gradient(135deg, lighten($clr-secondary, 25%) 0%, $clr-secondary 100%);
            background-clip: text;
            color: transparent;
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }
    li {
        margin-top: 0.75em;
        padding-left: 1.25em;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            height: 6px;
            width: 6px;
            border: solid 2px $clr-primary;
            border-radius: 100%;
        }
        &::after {
            content: '';
            position: absolute;
            top: 11px;
            left: -2px;
            border-bottom: solid 2px $clr-primary;
            width: 8px;
        }
    }

    strong:first-of-type {
        font-size: 1.15em;
        font-size: $font-medium;
    }

    &--container {
        border-radius: $border-radius;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;

        &:nth-child(even) {
            margin-top: 3rem;
        }
    }
}
.page-content__figure {
    border-radius: 10px;
    padding-top: 75%;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.page-icon {
    border: solid 2px $clr-primary;
    border-color: rgba($clr-primary, 0.6);
    border-radius: 100%;
    display: block;
    height: 60px;
    line-height: 75px;
    margin-bottom: 1rem;
    text-align: center;
    width: 60px;

    svg {
        display: inline-block;
        height: 34px;
        width: 34px;
    }
}

.arrows-circle {
    margin: 0 0 1rem 0;
    max-width: 120px;
}

.featured-content {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 2rem;
}
.featured-content__image {
    background-color: $clr-shade;
    border: solid 1px $clr-shade;
    border-radius: 10px;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/*------------------------------------*\
    #FOOTER
\*------------------------------------*/

.footer {
    background: $clr-secondary linear-gradient(180deg, $clr-secondary 0%, darken($clr-secondary, 10%) 100%);
    color: #fff;
}

.footer__upper {
    padding-top: 3rem;
    position: relative;
    overflow: hidden;
}
.footer__roles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.4em;
    font-weight: 100;
    color: darken($clr-secondary, 4%);
    opacity: 0.35;
    pointer-events: none;
}
.footer__roles-track {
    display: inline-flex;

    span {
        display: block;
        white-space: nowrap;
        margin-right: 0.3rem;
    }
}
.footer__upper .inner {
    position: relative;
    z-index: 1;
}
.footer__logo h3 {
    display: inline-block;
    margin: 0;
    max-width: 260px;
}
.footer__socials {
    flex: 1;
    @include flex-row;
    align-items: center;
    justify-content: flex-end;
}

.social {
    @include vertical-center;
    color: $clr-white;
    border: solid 2px $clr-white;
    border-radius: 100%;
    height: 46px;
    margin-left: 1rem;
    width: 46px;
    transition:
        border-color 300ms ease-in-out,
        color 300ms ease-in-out;

    svg {
        display: inline-block;
        height: 22px;
        width: 22px;
    }

    &.twitter:hover {
        border-color: #1DA1F2;
        color: #1DA1F2;
    }
    &.linkedin:hover {
        border-color: #0077b5;
        color: #0077b5;
    }
    &.facebook:hover {
        border-color: #4267B2;
        color: #4267B2;
    }
}

.footer__middle {
    padding: 3rem 0;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 75%;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2333393f' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
    }
}
.footer__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    position: relative;
    z-index: 1;
}

.footer__title {
    @include flex-row;
    align-items: center;
    border-bottom: solid 1px #fff;
    border-bottom-color: rgba(#fff, 0.1);
    font-size: 1.15em;
    font-size: $font-medium;
    margin-bottom: 1rem;
    padding-bottom: 0.75rem;

    span {
        flex: 1;
    }

    img {
        opacity: 0.8;
        transform: scaleX(-1);
        height: 18px;
        width: 18px;
    }
}
.footer__list-item {
    @include flex-row;
    align-items: flex-start;
    font-size: $font-regular;
    margin-top: 0.3rem;

    &.--contact-detail:not(:nth-child(1)) {
        margin-top: 1rem;
    }

    svg {
        display: inline-block;
        height: 20px;
        margin-right: 10px;
        opacity: 0.5;
        position: relative;
        top: 2px;
        width: 10px;
    }

    &.--contact-detail svg {
        height: 30px;
        top: -3px;
        width: 15px;
    }
}
.footer__list-item a {
    color: rgba($clr-white, 0.9);
    text-decoration-color: rgba($clr-white, 0.1);

    &:hover {
        color: $clr-white;
        text-decoration-color: rgba($clr-white, 0.9);
    }
}

.awards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 2.5rem;
}
.award {
    border: solid 1px lighten($clr-secondary, 5%);
    border-radius: $border-radius;
    height: 74px;
    text-align: center;
    line-height: 70px;
}

.footer__lower {
    background-color: darken($clr-secondary, 15%);
    font-size: $font-regular;
    padding: 1rem 0;
    text-align: center;
}

.copyright {
    font-size: 0.8em;
}
.copyright__link {
    color: $clr-white;
    text-decoration-color: rgba($clr-white, 0.1);

    &:hover {
        color: $clr-white;
        text-decoration-color: rgba($clr-white, 0.9);
    }
}

/*------------------------------------*\
    #BREADCRUMB
\*------------------------------------*/

.breadcrumb {
    margin-bottom: 1rem;
}
.breadcrumb__item {
    display: inline-block;
    position: relative;
    padding-right: 2rem;

    &::before {
        content: '/';
        position: absolute;
        top: 0;
        right: 0.8rem;
        bottom: 0;
        transform: translateY(3px);
        color: darken($clr-shade, 15%);
    }
}
.breadcrumb__link {
    color: $clr-white;
    font-size: $font-xs;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration-color: $clr-white;

    &:hover {
        color: $clr-white;
        text-decoration-color: $clr-white;
    }
}

/*------------------------------------*\
    #MASTHEAD
\*------------------------------------*/

.masthead {
    background-color: darken($clr-secondary, 5%);
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &::before {
        background-image: url('/images/arrows-circle.svg');
        background-position: center;
        background-size: 100px;
        opacity: 0.45;
    }
    &--73::before {
        transform: scale(-1);
    }
    &::after {
        background: linear-gradient(90deg, darken($clr-secondary, 5%) 33.333%, rgba($clr-secondary, 0) 100%);
    }
}
.masthead__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;

    @supports (object-fit: cover) {
        height: 100%;
        object-fit: cover;
    }

    &.--contact {
        bottom: 0;
        top: initial;
        animation: background-image 60s linear infinite alternate;
        transform-origin: bottom center;
    }
}
.masthead__overlay {
    color: #fff;
    padding: 5rem 0;
    padding-top: calc(7.5rem + 90px);
    position: relative;
    z-index: 2;

    &.--image {
        color: $clr-white;
        text-shadow: 0 0 2px $clr-secondary;
    }

    &.--image::before {
        @include absolute-fill;
        content: '';
        background-color: $clr-secondary;
        opacity: 0.8;
        z-index: 1;
    }

    &.--contact {
        padding-top: 20rem;

        &::before {
            opacity: 0.6;
        }
    }
}
.masthead__content {
    max-width: 800px;
    position: relative;
    z-index: 1;
}
.masthead__thumbnail {
    border: solid 1rem $clr-white;
    border-radius: 10px;
    margin-bottom: -12rem;
    overflow: hidden;
    padding-top: 30%;
    padding-top: calc(30% - 1rem);
    position: relative;
    width: 40%;
}
.masthead__thumbnail-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.masthead__title {
    font-size: 2.8em;
    font-size: $font-xl;
    font-weight: normal;
    margin: 0;
}
.masthead__tagline {
    font-size: 1.25em;
    margin-top: 0.75rem;
}

// Additional "split screen"
.masthead__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    position: relative;
    z-index: 1;
}
.masthead__text {
    border-radius: $border-radius;
    background-color: $clr-secondary;
    background-color: rgba($clr-secondary, 0.8);
    padding: 3rem;
}
.masthead__object {
    border-radius: $border-radius;
    background-color: $clr-shade;
    position: relative;

    iframe {
        border: solid 8px #fff;
        border-radius: $border-radius;
        width: 100%;
        height: 100%;
    }
}

/*------------------------------------*\
    #INTRODUCTION
\*------------------------------------*/

.intro {
    background-color: $clr-white;
}
.intro__container {
    @include flex-row;
    align-items: center;
}
.intro__main {
    flex: 1;
    margin-right: 4rem;
    padding: 4rem 0;
}
.intro__aside {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 4rem 0;
    position: relative;
}
.intro__image {
    padding-top: 120%;
    position: relative;
    width: 100%;

    &::before {
        background: $clr-light-shade;
        border-radius: 10px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 90%;
        width: 100%;
        transform: translate(12%,12%);
    }
}
.intro__image-1,
.intro__image-2 {
    position: absolute;
}
.intro__image-1 {
    top: 0;
    left: 0;
    height: 90%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.intro__image-2 {
    right: 20%;
    bottom: 0;
    max-width: 170px;
    width: 110%;
}

/*------------------------------------*\
    #SERVICES
\*------------------------------------*/

#WordCloud {
    height: 460px;
    width: 100%;
}

/*------------------------------------*\
    #HIGHLIGHTS
\*------------------------------------*/

.whychoose {
    background: $clr-secondary linear-gradient(180deg, $clr-secondary 0%, darken($clr-secondary, 10%) 100%);
}
.whychoose__slide {
    align-items: center;
    border-right: solid 2px darken($clr-secondary, 10%);
    color: #fff;
    display: inline-flex;
    padding: 0.5rem 0.25rem;
    justify-content: center;

    svg {
        display: inline-block;
        height: 16px;
        margin-right: 8px;
        width: 16px;
    }
}

// .whychoose {
//     background-color: #fff;
//     border-top: solid 1px $clr-shade;
// }
// .whychoose-highlights {
//     display: flex;
//     flex-flow: row wrap;
//     gap: 3rem;
// }
// .whychoose-highlight {
//     flex: 1;
//     min-width: 320px;
//     text-align: center;
// }
// .whychoose-highlight__icon {
//     display: inline-block;
//     margin: 0 auto 0.5rem;

//     svg {
//         opacity: 0.25;
//         height: 70px;
//         width: 70px;
//     }

//     &--tilted {
//         transform: rotate(45deg);
//     }
// }
// .whychoose-highlight__title {

//     @supports (background-clip: text) or (-webkit-background-clip: text) {
//         background: $clr-secondary linear-gradient(135deg, lighten($clr-secondary, 25%) 0%, $clr-secondary 100%);
//         background-clip: text;
//         color: transparent;
//     }
// }

/*------------------------------------*\
    #CTA
\*------------------------------------*/

.cta {
    position: relative;
    overflow: hidden;
}
.cta__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $clr-secondary;
}
.cta__image {
    height: 100%;
    width: 100%;

    @supports (object-fit:cover) {
        object-fit: cover;
    }
}
.cta__overlay {
    padding: 6rem 0;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(145deg, lighten($clr-primary, 15%) 0%, $clr-primary 100%);
        opacity: 0.66;
    }
}
.cta__content {
    background-color: rgba($clr-primary, 0.8);
    border-radius: 10px;
    color: $clr-white;
    padding: 3rem 2rem;
    position: relative;
    text-align: center;
}
.cta__title {
    font-size: 1.6em;
    font-size: $font-large;
    margin-bottom: 1.5rem;
}
.cta__tagline {
    font-size: 1.15em;
    font-size: $font-medium;
}
.cta__content .button {
    margin-top: 2rem;
}

/*------------------------------------*\
    #ASSIGNMENTS
\*------------------------------------*/

.assignments__container {
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;

    &.--center {
        justify-content: center;
    }
}
.assignment-listing {
    flex: 1;
    max-width: 33.333%;
}
.assignment-listing__thumbnail {
    border-radius: 10px;
    display: block;
    overflow: hidden;
    padding-top: 62.5%;
    position: relative;
    transition: opacity 350ms ease-in-out;
}
.assignment-listing__thumbnail:hover {
    opacity: 0.8;
}
.assignment-listing__thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @supports (object-fit:cover) {
        object-fit: cover;
    }
}
.assignment-listing__company-logo {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 3;
    max-width: 120px;
}
.assignment-listing__details {
    margin-top: 1rem;
}
.assignment-listing__title {
    font-size: 1.6em;
    font-size: $font-large;
}
.assignment-listing__link {
    margin-top: 1rem;

    svg {
        display: inline-block;
        margin-left: 5px;
        height: 10px;
        transition: transform 300ms linear;
        width: 20px;
        will-change: transform;
    }

    &:hover svg {
        transform: translateX(25%);
    }
}

// ASSIGNMENT PAGE
.assignment-details {
    background-color: #fff;
    border: solid 1px $clr-shade;
    border-radius: 10px;
    margin: -6rem 1rem 0 0;
    padding: 1rem;
    position: sticky;
    top: 120px;
    z-index: 1;
}
.assignment-details__title {
    margin-bottom: 1.5rem;
}
.assignment-detail__highlight {
    margin-top: 1rem;
}
.assignment-detail__title {
    color: darken($clr-shade, 25%);
}
.assignment-detail__text {
    font-size: 1.6em;
    font-size: $font-medium;
}
.assignment-details__action {
    margin-top: 1.75rem;

    a {
        display: flex;
        align-items: center;
        margin-top: 1rem;
    }

    svg {
        display: inline-block;
        height: 18px;
        width: 18px;
        min-width: 18px;
        margin-right: 6px;
    }
}
.assignment-details__subtitle {
    font-size: 1.6em;
    font-size: $font-medium;
    margin-bottom: 0.5rem;
}
.assignment-details__text {
    margin: 0 0 1rem;
}

// SHARERS
.sharers {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: solid 1px $clr-shade;
}
.sharers .social {
    border-color: $clr-text;
    color: $clr-text;
}

/*------------------------------------*\
    #CONTACT PAGE
\*------------------------------------*/

.contact-details {
    margin-top: 2rem;
}
.contact-detail {
    @include flex-row;
    font-size: $font-regular;
    margin-top: 1rem;
}
.contact-detail__icon {
    margin-right: 1rem;
}
.contact-detail__icon-wrap {
    @include vertical-center;
    width: 2rem;
    height: 2rem;
    border: solid 1px darken($clr-primary, 5%);
    border-radius: 100%;
    line-height: 2rem;
    color: $clr-white;
    background-color: $clr-primary;

    svg {
        display: inline-block;
        height: 1.1rem;
        width: 1.1rem;
        position: relative;
    }
}
.contact-detail__text {
    flex: 1;
    margin: 0;
}

/*------------------------------------*\
    #CLIENTS
\*------------------------------------*/



/*------------------------------------*\
    #PAGE NOT FOUND
\*------------------------------------*/

@keyframes glitch {
    2%, 64%{
      transform: translate(2px,0) skew(0deg);
    }
    4%, 60%{
      transform: translate(-2px,0) skew(0deg);
    }
    62%{
      transform: translate(0,0) skew(5deg); 
    }
}

@keyframes glitchTop {
    2%, 64%{
      transform: translate(2px,-2px);
    }
    4%, 60%{
      transform: translate(-2px,2px);
    }
    62%{
      transform: translate(13px,-1px) skew(-13deg); 
    }
}

@keyframes glitchBottom {
    2%, 64%{
      transform: translate(-2px,0);
    }
    4%, 60%{
      transform: translate(-2px,0);
    }
    62%{
      transform: translate(-22px,5px) skew(21deg); 
    }
}  

.page-not-found {
    @include vertical-center;
    background: $clr-black radial-gradient(lighten($clr-black, 18%) 0%, $clr-black 45%);
    height: 100vh;
    min-height: 600px;
    padding: 124px 0;
}
.page-not-found__wrap {
    color: $clr-white;
    max-width: 768px;
    margin: 0 auto;
    padding: $padding;
    text-align: center;
}
.page-not-found__title {
    animation: glitch 1s linear infinite;
    color: inherit;
    display: inline-block;
    font-size: 3em;
    letter-spacing: 0.5px;
    position: relative;
    text-transform: uppercase;

    &::before,
    &::after {
        content: attr(title);
        position: absolute;
        left: 0;
    }

    &::before {
        animation: glitchTop 1s linear infinite;
        clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    }

    &::after {
        animation: glitchBottom 1.5s linear infinite;
        clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
        -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    }
}

/*------------------------------------*\
    #MEDIA QUERIES
    We group media queries as much as 
    possible to prevent duplication and 
    keep the amount of breakpoints to 
    a minimum.
\*------------------------------------*/

@media screen and (max-width: 1220px) {

    // TEMPLATE
    .featured-content {
        grid-template-columns: 1fr 1.5fr;
    }

    // ASSIGNMENTS PAGE
    .assignment-details {
        top: 100px;
    }
}

@media screen and (max-width: 1024px) {

    // FOOTER
    .footer__container {
        grid-template-columns: 1fr 1fr;
    }
    .footer__col:nth-child(3) {
        grid-column: 1/3;
    }
    .footer__list--contact-details {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        gap: 1.5rem;
    }
    .footer__list-item.--contact-detail {
        flex: 1;
        margin-top: 0 !important;
    }

    // ASSIGNMENTS
    .assignment-listing {
        min-width: 50%;
        min-width: calc(50% - 1rem);
    }

    // CONTACT PAGE
    .masthead__overlay.--contact {
        padding-top: 14rem;
    }
    .masthead__container {
        grid-template-columns: 1fr;
    }
    .masthead__object {
        padding-top: 50%;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 980px) {

    // ASSIGNMENTS PAGE
    .assignment-details {
        top: 94px;
    }
}

@media screen and (max-width: 930px) {

    // ASSIGNMENTS PAGE
    .assignment-details {
        top: 100px;
    }

    // MASTHEAD
    .masthead__tagline {
        font-size: 1.125em;
    }
}

@media screen and (max-width: 840px) {

    // TEMPLATE
    .featured-content {
        grid-template-columns: 1.8fr 4fr;
        padding: 0;
    }
    .page-content__text {
        margin-top: 2rem;
    }

    // WHY CHOOSE
    .whychoose-highlights { 
        padding:2em 0; 
        }
    .whychoose-highlight { 
        width:100%; 
        padding:0; 
        border:0 !important;
        }

    // FOOTER
    .footer__container {
        grid-template-columns: 1fr;
        padding: 0 20%;
    }
    .footer__container--upper {
        display: block;
        text-align: center;
    }
    .footer__col {
        background-color: $clr-secondary;
        border-radius: $border-radius;
        padding: 1.25rem;
    }
    .footer__socials {
        justify-content: center;
        margin-top: 2rem;
    }
    .footer__socials .social:first-child {
        margin-left: 0;
    }
    .footer__list--contact-details {
        display: block;
    }
    .footer__list-item.--contact-detail:first-child {
        margin-bottom: 0.75rem !important;
    }
    .footer__list-item.--contact-detail {
        margin-top: 0.75rem !important;
    }
    .footer__col:nth-child(3) {
        grid-column: 1/2;
    }
    .copyright {
        padding: 0 20%;
    }

    // INTRO
    .intro__main {
        margin-right: 0;
    }
    .intro__aside {
        display: none;
    }

    // PAGE-CONTENT
    .page-content--container {
        display: flex;
        flex-direction: column;
        gap: 0;

        &:nth-child(odd) {
            flex-direction: column-reverse;
        }
    }
    .page-content__figure {
        padding-top: 55.25%;
    }
}

@media screen and (max-width: 768px) {
    
    // ASSIGNMENTS PAGE
    .assignment-details {
        margin: -6rem 2rem 0 2rem;
        max-width: 100%;
    }
    .assignment-details__container {
        display: flex;
        gap: 1rem;
    }
    .assignment-details__main {
        min-width: 200px;
    }
    .assignment-details__action {
        flex: 1;
        margin-top: 0;
    }
    .assignment-detail__highlight:first-child {
        margin-top: 0;
    }
}

@media screen and (max-width: 700px) {

    // HIGHLIGHTS
    .assignment-details {
        padding: 1.5rem;
    }
    .assignment-details__container {
        display: block;
    }
    .assignment-details__action {
        border-top: solid 1px $clr-shade;
        margin-top: 1rem;
        padding-top: 1rem;
    }
}

@media screen and (max-width: 600px) {

    // TEMPLATE
    .featured-content {
        grid-template-columns: 1fr;
        text-align: center;
    }
    .featured-content__image {
        margin: 0 auto;
        padding-top: 50%;
        width: 50%;
    }

    // MASTHEAD
    .masthead__tagline {
        font-size: 1.025em;
    }

    // WORDCLOUD
    .arrows-circle {
        max-width: 90px;
    }
    .wordcloud .inner {
        padding: 0;
    }
    #WordCloud {
        height: 500px;
        transform: scale(1.1);
    }

    // ASSIGNMENT PAGE
    .assignment-details {
        margin: -5rem 1rem 0;
        padding: 1rem;
    }
    .assignment-details__title {
        margin-bottom: 0.75rem;
    }
    .assignment-details__main {
        display: flex;
        gap: 1rem;
        min-width: 100%;
    }
    .assignment-detail__highlight {
        flex: 1;
        min-width: 100px;
        margin-top: 0;
    }
}

@media screen and (max-width: 525px) {

    // MASTHEAD
    .masthead__container {
        grid-template-columns: 1fr;
    }
    .masthead__object {
        height: 400px;
    }
    .masthead__overlay {
        padding: calc(3rem + 78px) 0 3rem;
    }
    .masthead__text {
        padding: 1.5rem;
    }
    .masthead__overlay.--contact {
        padding-top: 3rem;
        padding-top: calc(3rem + 78px);
    }

    // INTRODUCTION
    .intro__container {
        display: block;
    }
    .intro__main {
        margin-right: 0;
        padding: 3rem 1rem;
        text-align: center;
    }
    .intro__aside {
        display: none;
    }

    // SERVICES
    .services__container {
        display: block;
    }
    .services__content {
        padding: 0 1rem;
        margin-bottom: 2rem;
        width: 100%;
    }
    .services__cards {
        max-width: 100%;
        padding-left: 1rem;
    }
    .service-listing__overlay {
        padding: 0.725rem 1.125rem;
    }

    // CTA
    .cta__overlay {
        padding: 0;
    }
    .cta__container {
        display: block;
    }
    .cta__content {
        border-radius: 0;
        width: 100%;
    }
    .cta__col {
        display: none;
    }
    .cta__overlay .inner {
        padding: 0;
    }

    // FOOTER
    .footer__upper {
        padding: 3rem 0 0 0;
    }
    .footer__logo {
        text-align: center;
    }
    .footer__container {
        padding: 0 1rem;
    }
    .copyright {
        padding: 0;
    }

    // SOCIAL
    .social {
        height: 44px;
        width: 44px;

        svg {
            height: 22px;
            width: 22px;
        }
    }

    // ASSIGNMENTS
    .assignment-listing {
        min-width: 100%;
    }

    // ASSIGNMENTS PAGE
    .assignment-details {
        margin: 0;
    }
}

@media screen and (max-width: 478px) {

    // ASSIGNMENTS PAGE
    .assignment-details__container,
    .assignment-details__main {
        display: block;
    }
    .assignment-detail__highlight {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 425px) {

    // TEMPLATE
    .featured-content {
        gap: 1rem;
    }

    // PORTALS
    .portal__title {
        font-size: 1.215em;
    }
    .portal__tagline {
        font-size: 0.835em;
    }

    // WORDCLOUD
    .arrows-circle {
        max-width: 80px;
    }
    #WordCloud {
        height: 400px;
        transform: scale(1.15);
    }

    // FOOTER
    .footer__upper__container {
        display: block;
    }
    .footer__logo {
        margin: 0 auto 1.25rem;
        max-width: 220px;
        text-align: center;
        width: 100%;
    }
    .footer__socials {
        justify-content: center;
        width: 100%;
    }
    .social:first-child {
        margin-left: 0;
    }

    // ASSIGNMENT
    .assignment-details {
        border: 0;
        padding: 0;
    }
    
}

@media screen and (max-width: 375px) {

    // ASSIGNMENTS PAGE
    .assignment-detail__highlight {
        display: block;
    }
}